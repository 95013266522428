import React from 'react';
import PropTypes from 'prop-types';

import styles from './post-card.module.css';
import { Link } from 'gatsby';

const PostCard = ({ title, date, children, routeName, href, tags = [] }) => (
  <div className={styles.postCard}>
    {routeName ? (
      <Link to={routeName} className={styles.titleLink}>
        <h3 className={styles.title}>{title}</h3>
      </Link>
    ) : (
      <a
        href={href}
        target="_blank"
        rel="noreferrer"
        className={styles.titleLink}
      >
        <h3 className={styles.title}>{title}</h3>
      </a>
    )}
    <div className={styles.date}>{date}</div>
    <div className={styles.intro}>{children}</div>

    {routeName ? (
      <Link
        to={routeName}
        className={styles.readMore}
        title={'Read more about "' + title + '"'}
      >
        &gt;&gt;&gt; Read more &lt;&lt;&lt;
      </Link>
    ) : (
      <a
        href={href}
        target="_blank"
        rel="noreferrer"
        className={styles.readMore}
        title={'Read more about "' + title + '"'}
      >
        &gt;&gt;&gt; Read more &lt;&lt;&lt;
      </a>
    )}

    <div className={styles.pills}>
      {tags.map((tag) => (
        <div key={tag} className={styles.pill}>
          {tag}
        </div>
      ))}
    </div>
  </div>
);

PostCard.propTypes = {
  children: PropTypes.node.isRequired,
  date: PropTypes.string.isRequired,
  routeName: PropTypes.string,
  href: PropTypes.string,
  tags: PropTypes.array,
  title: PropTypes.string.isRequired,
};

export default PostCard;
